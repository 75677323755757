import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import andorid from './pub/articles/android.md'
import ce from './pub/articles/ce.md'
import feistel from './pub/articles/feistel.md'
import infix from './pub/articles/infix.md'
import mini_block from './pub/articles/mini_block.md'
import error from './pub/articles/error.md'

class Article extends Component{
    constructor() {
      super();
      this.state = { markdown: '' };
    }
  
    componentWillMount() {
      const title = this.props.title;
      if(title === 'andorid'){
        fetch(andorid).then(res => res.text()).then(text => this.setState({ markdown: text }));
      }
      else if(title === 'ce'){
        fetch(ce).then(res => res.text()).then(text => this.setState({ markdown: text }));
      }
      else if(title === 'fiestel'){
        fetch(feistel).then(res => res.text()).then(text => this.setState({ markdown: text }));
      }
      else if(title === 'infix'){
        fetch(infix).then(res => res.text()).then(text => this.setState({ markdown: text }));
      }
      else if(title === 'mini_block'){
        fetch(mini_block).then(res => res.text()).then(text => this.setState({ markdown: text }));
      }
      else{
        fetch(error).then(res => res.text()).then(text => this.setState({ markdown: text }));
      }
      
    }
  
    render() {
      const { markdown } = this.state;
      return <ReactMarkdown className="article" children={markdown} />;
    }
  }
  
  export default Article;
  
import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import './App.css';
import Projects from './projects';
import 'bootstrap/dist/css/bootstrap.css';

import resume from './pub/TempletonResumeJanuary2024.pdf'
import ethics from './pub/Ethics.pdf'
import general_education_reflection from './pub/General_Education_Reflection.pdf'
import cumulative_reflection from './pub/General_Education_Reflection.pdf'


function Documents() {

  return (
    <div>
      <div className="padded row">
        <div className="col-sm-6">
          <h3><b> Resume</b></h3>
          <p>Take a peek!</p>
          <iframe src={resume} width="100%" height="500vw" type="application/pdf"></iframe>
        </div>
        <div className="col-sm-6">
          <h3><b>Personal Ethics</b></h3>
          <p>How personal ethics correlate into cyber security</p>
          <iframe src={ethics} width="100%" height="500vw" type="application/pdf"></iframe>
        </div>
      </div>
      <div className="padded row">
        <div className="col-sm-6">
          <h3><b>General Education Reflection</b></h3>
          <p>My thoughts on how General Education helped my development</p>
          <iframe src={general_education_reflection} width="100%" height="500vw" type="application/pdf"></iframe>
        </div>
        <div className="col-sm-6">
          <h3><b>Cumulative Reflection Reflection</b></h3>
          <p>A short summary of my experience at Iowa State University</p>
          <iframe src={cumulative_reflection} width="100%" height="500vw" type="application/pdf"></iframe>
        </div>
      </div>
    </div>
  );
}

export default Documents;

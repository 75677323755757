import { useState } from 'react';
import logo from './pub/logo.png';
import li from './pub/in.png';
import git from './pub/giit.png';
import resume from './pub/TempletonResumeJanuary2024.pdf'
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


function Resume() {
  return (
    <div>
      <iframe src={resume} width="95%" height="500vw" type="application/pdf"></iframe>
    </div>
  );
}

export default Resume;

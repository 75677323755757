import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './App.css';
import Article from './article';

import 'bootstrap/dist/css/bootstrap.css';

import class_diagram from './pub/articles/thumb/class_diagram.png';
import fiesel from './pub/articles/thumb/Feistel.png';
import cardinaleats from './pub/articles/thumb/cardinaleatsthumb.png';
import infix from './pub/articles/thumb/Infix2Postfix.png'
import mini_block from './pub/articles/thumb/mini_block.png'

const customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      opacity: '85%',
      height: '80%',
      backgroundColor: '#000000',
      borderRadius: '10px',
    },
  };
  
function Projects() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");

    function openModal(title) {
      setModalTitle(title);
      setIsOpen(true);
    }
  
    function afterOpenModal() {
    }
  
    function closeModal() {
      setIsOpen(false);
    }
  
    return (
        <div>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Project Modal"
                >
                    <button onClick={closeModal} type="button" class="article-btn btn-close btn-close-white" aria-label="Close"></button>
                    <Article title={modalTitle}/>

                </Modal>
            </div>
            
            <div className="tile-padded row">
                <div className="tile-col col-sm-4">                
                    <div onClick={() =>  openModal("android")} className='tile'>
                        <div className='tile-pad' />
                        <img className='tile-img' src={class_diagram} />
                        <h3>Android App</h3>
                    </div>
                </div>
                <div className="tile-col col-sm-4">
                    <div onClick={() =>  openModal("fiestel")} className='tile'>
                        <div className='tile-pad' />
                        <img className='tile-img' src={fiesel} />
                        <h3>Feistel</h3>
                    </div>
                </div>
                <div className="tile-col col-sm-4">
                    <div onClick={() =>  openModal("ce")} className='tile'>
                        <div className='tile-pad' />
                        <img className='tile-img' src={cardinaleats} />
                        <h3>Cardinal Eats</h3>
                    </div>
                </div>
            </div>
            <div className="tile-padded row">
                <div className="tile-col col-sm-4">
                    <div onClick={() =>  openModal("infix")} className='tile'>
                        <div className='tile-pad' />
                        <img className='tile-img' src={infix} />
                        <h3>Infix to Postfix</h3>
                    </div>
                </div>
                <div className="tile-col col-sm-4">
                    <div onClick={() =>  openModal("mini_block")} className='tile'>
                        <div className='tile-pad' />
                        <img className='tile-img' src={mini_block} />
                        <h3>Mini Block Cipher</h3>
                    </div>
                </div>
                <div className="tile-col col-sm-4">
                    <div className='tile'>
                        <div className='tile-pad' />
                        <img className='tile-img' src={infix} />
                        <h3>Personal Processor</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Projects;
